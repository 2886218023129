<template>
    <div class="card mb-3">
        <div class="card-header">
            <div class="row align-items-center justify-content-between">
                <div class="col-sm-auto mb-2 mb-sm-0">
                    <h5 class="fs-0 mb-0 text-nowrap">Shipments</h5>
                </div>
                <div class="col-sm-9 col-md-8 col-lg-6 col-xxl-5 d-flex align-items-center">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="Search..."
                           aria-label="Search"
                           v-model="search">
                    <router-link :to="{ name: 'create-shipment' }"
                                 class="btn btn-sm btn-falcon-default text-nowrap ml-2">
                        <i class="fas fa-plus" data-fa-transform="shrink-3" /> Create Shipment
                    </router-link>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <LoadingSpinner v-if="loading" class="my-5" />
            <LoadingError v-else-if="error" class="my-6" />
            <VTable v-else-if="shipments.length"
                    :data="shipments"
                    :filter="search"
                    :filter-fields="['identifier', 'order.bigcommerce_order_id', 'created_formatted']"
                    :initial-sort="{ field: 'identifier', order: false }"
                    :page-size="15">
                <template #header="{ sort, sortBy }">
                    <tr>
                        <VTableHeader field="identifier" v-bind="{ sort, sortBy }">
                            ID
                        </VTableHeader>
                        <VTableHeader field="order.bigcommerce_order_id" v-bind="{ sort, sortBy }">
                            Order ID
                        </VTableHeader>
                        <VTableHeader field="created" v-bind="{ sort, sortBy }">
                            Date
                        </VTableHeader>
                    </tr>
                </template>
                <template #row="{ item: shipment }">
                    <tr :id="shipment.id">
                        <th class="py-2 align-middle white-space-nowrap">
                            <router-link :to="{ name: 'shipment-details', params: { id: shipment.id }}">
                                <strong>#{{ shipment.identifier }}</strong>
                            </router-link>
                        </th>
                        <td class="py-2 align-middle white-space-nowrap">
                            <router-link v-if="shipment.order"
                                         :to="{ name: 'order-details', params: { id: shipment.order.id }}">
                                #{{ shipment.order.bigcommerce_order_id }}
                            </router-link>
                            <span v-else class="text-600 font-weight-bold ml-2">&mdash;</span>
                        </td>
                        <td class="py-2 align-middle">{{ shipment.created_formatted }}</td>
                    </tr>
                </template>
            </VTable>
            <div v-else class="d-flex align-items-center justify-content-center flex-column p-3" style="height: 300px;">
                <i class="fas fa-plane-slash" style="font-size: 2rem;" />
                <span class="mt-3">No shipments created yet.</span>
                <small class="mt-2 w-50 text-center">
                    Once you create your first shipment, it will appear in this section
                </small>
            </div>
        </div>
    </div>
</template>

<script>
    import { formatISODate } from '../filters';
    import { getShipmentList } from '../services/ShipmentService';
    import LoadingError from '../components/LoadingError';
    import LoadingSpinner from '../components/LoadingSpinner';
    import VTable from '../components/VTable.vue';
    import VTableHeader from '../components/VTableHeader.vue';

    export default {
        name: 'Shipments',
        components: {
            LoadingError,
            LoadingSpinner,
            VTable,
            VTableHeader,
        },
        data() {
            return {
                loading: true,
                error: null,
                shipments: [],
                search: '',
            };
        },
        async mounted() {
            try {
                const { data } = await getShipmentList();
                data.forEach(shipment => {
                    shipment.created_formatted = formatISODate(shipment.created, 'MMM d, YYY p');
                });
                this.shipments = data;
            } catch (err) {
                this.error = err;
                this.$alerts.danger('An error occurred',
                                    'This page failed to load. Please contact support if this problem persists.');
            } finally {
                this.loading = false;
            }
        },
    };
</script>
